import {gql} from '@apollo/client';
import {AdresaDetailValueFragment, AdresaFragment} from '../../Adresa';
import {CommonFragments} from '../../Common/graphql/CommonFragments';
import {ObchodniPartnerIcDetailValueFragment, ObchodniPartnerNazevDetailValueFragment, ObchodniPartnerTableCellFragment} from '../../ObchodniPartner';

export const OdbernaMistaQueries = {
    gql: {
        odbernaMistaStatusCiselnik: gql`
            query OdbernaMistaStatus {
                nastaveni {
                    odbernaMista {
                        statusyHistorieSpotrebElektrina {
                            id
                            klic
                            nazev
                            popis
                            typ
                        }
                    }
                }
            }
        `,
        odbernaMistaNotifcation: gql`
            query OdbernaMistaNotifikace($id: ID!) {
                elektrina {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            elektrina {
                                notifikace {
                                    hdo
                                    odstavky
                                    poruchy
                                }
                            }
                        }
                    }
                }
            }
        `,
        odbernaMistaElektrina: gql`
            query OdbernaMistaElektrina(
                $first: Int
                $last: Int
                $after: Cursor
                $before: Cursor
                $filter: SmlouvyOdbernychMistFilter
                $showVyrobniEan: Boolean!
                $callCount: Boolean!
            ) {
                elektrina {
                    odbernaMista {
                        smlouvyOdbernychMist(
                            first: $first
                            last: $last
                            after: $after
                            before: $before
                            filter: $filter
                            orderBy: {column: datumOd, asc: false}
                        ) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            totalCount @include(if: $callCount)
                            edges {
                                node {
                                    id
                                    zakaznik {
                                        ...ObchodniPartnerTableCell
                                    }
                                    elektrina {
                                        eanSpotrebni
                                        eanVyrobni @include(if: $showVyrobniEan)
                                        adresa {
                                            ...AdresaFragment
                                        }
                                        vyrobna
                                        hdo {
                                            kodyHdo
                                        }
                                        typTarifu {
                                            id
                                            klic
                                        }
                                        napetovaUroven
                                        smartMereniAktivni
                                        pristroje {
                                            id
                                            idPristroje
                                            cislo
                                            material
                                            hdo
                                            datumOd
                                            datumDo
                                        }
                                        kategorieMereni {
                                            kategorieMereni
                                            platnostDo
                                            platnostOd
                                        }
                                    }
                                    typMereni
                                    stav
                                    datumOd
                                    datumDo
                                    nazev
                                    komodita
                                }
                            }
                        }
                    }
                }
                nastaveni {
                    odbernaMista {
                        materialyPristrojuSmartMereniElektrina {
                            id
                            klic
                            nazev
                            aktivni
                            zobrazitSkupine
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
            ${ObchodniPartnerTableCellFragment.zakaznik}
            ${AdresaFragment.adresa}
        `,

        odbernaMistaPlyn: gql`
            query OdbernaMistaPlyn($first: Int, $last: Int, $after: Cursor, $before: Cursor, $filter: SmlouvyOdbernychMistFilter, $callCount: Boolean!) {
                plyn {
                    odbernaMista {
                        smlouvyOdbernychMist(
                            first: $first
                            last: $last
                            after: $after
                            before: $before
                            filter: $filter
                            orderBy: {column: datumOd, asc: false}
                        ) {
                            pageInfo {
                                ...PaginationPageInfo
                            }
                            edges {
                                node {
                                    id
                                    zakaznik {
                                        ...ObchodniPartnerTableCell
                                    }
                                    plyn {
                                        eic
                                        adresa {
                                            ...AdresaFragment
                                        }
                                    }
                                    typMereni
                                    stav
                                    datumOd
                                    datumDo
                                    nazev
                                }
                            }
                            totalCount @include(if: $callCount)
                        }
                    }
                }
            }
            ${CommonFragments.paginationPageInfo}
            ${ObchodniPartnerTableCellFragment.zakaznik}
            ${AdresaFragment.adresa}
        `,

        odberneMistoElektrina: gql`
            query OdberneMistoElektrinaMaterialyPristrojuSmartMereni($id: ID!, $showVyrobniEan: Boolean!) {
                elektrina {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            komodita
                            typMereni
                            stav
                            zakaznik {
                                id
                                typ
                                ...ObchodniPartnerNazevDetailValue
                                ...ObchodniPartnerIcDetailValue
                                adresa {
                                    ...AdresaDetailValue
                                }
                            }
                            smlouvy {
                                counter
                                smlouva {
                                    id
                                }
                            }
                            datumOd
                            datumDo
                            nazev
                            elektrina {
                                kategorieMereni {
                                    kategorieMereni
                                    platnostDo
                                    platnostOd
                                }
                                odecetFrekvence
                                pocetFaziVyrobna
                                obdobiPeriodickehoOdectu
                                obchodnik {
                                    ic
                                    nazevSpolecnosti
                                }
                                stav
                                utpDmsLink
                                hdo {
                                    nastavenoUzivatelem
                                    kodyHdo
                                    aktivniSazby {
                                        endDate
                                        startDate
                                        sazby {
                                            dny {
                                                casy {
                                                    od
                                                    do
                                                }
                                                denVTydnu
                                            }
                                            sazba
                                        }
                                        region
                                    }
                                    neaktivniSazby {
                                        endDate
                                        startDate
                                        sazby {
                                            dny {
                                                casy {
                                                    od
                                                    do
                                                }
                                                denVTydnu
                                            }
                                            sazba
                                        }
                                        region
                                    }
                                }
                                sop {
                                    aktivni {
                                        cislo
                                        dmsLink
                                        dodatky {
                                            dmsLink
                                        }
                                        platnostDo
                                        platnostOd
                                    }
                                    rozpracovana {
                                        cislo
                                        hash
                                    }
                                }
                                pristroje {
                                    id
                                    idPristroje
                                    cislo
                                    material
                                    hdo
                                    datumOd
                                    datumDo
                                }
                                rezervovanyVykon
                                rezervovanyPrikon
                                kapacitaRocni
                                kapacitaMesicni
                                hodnotaJistice {
                                    hodnota
                                    platnostDo
                                    platnostOd
                                    type
                                }
                                pocetFazi {
                                    hodnota
                                    platnostDo
                                    platnostOd
                                    type
                                }
                                distribucniSazba {
                                    platnostDo
                                    platnostOd
                                    sazba {
                                        id
                                        nazev
                                        klic
                                        popis
                                        typTarifu {
                                            id
                                            klic
                                            nazev
                                        }
                                    }
                                }
                                typTarifu {
                                    id
                                    nazev
                                    klic
                                }
                                regulacniStupne {
                                    rs3
                                    rs4
                                    rs5
                                    rs6
                                    rs7Kw
                                    rs7Hod
                                    kontakt {
                                        jmeno
                                        prijmeni
                                        telefon
                                        email
                                    }
                                }
                                tdd
                                eanSpotrebni
                                eanVyrobni @include(if: $showVyrobniEan)
                                typZdroje
                                kategorieVyrobce
                                vyrobna
                                napetovaUroven
                                smartMereniAktivni
                                adresa {
                                    ulice
                                    cisloPopisne
                                    cisloOrientacni
                                    mistniCast
                                    mesto
                                    psc
                                }
                                typ
                            }
                        }
                    }
                }
            }
            ${AdresaDetailValueFragment.adresa}
            ${ObchodniPartnerNazevDetailValueFragment.obchodniPartner}
            ${ObchodniPartnerIcDetailValueFragment.obchodniPartner}
        `,

        odberneMistoPlyn: gql`
            query OdberneMistoPlynSmlouva($id: ID!) {
                plyn {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            komodita
                            typMereni
                            stav
                            nazev
                            zakaznik {
                                id
                                typ
                                ...ObchodniPartnerNazevDetailValue
                                ...ObchodniPartnerIcDetailValue
                                adresa {
                                    ...AdresaDetailValue
                                }
                            }
                            datumOd
                            datumDo
                            plyn {
                                eic
                                stav
                                odecetFrekvence
                                obdobiPeriodickehoOdectu
                                obchodnik {
                                    ic
                                    nazevSpolecnosti
                                }
                                sop {
                                    aktivni {
                                        cislo
                                        dmsLink
                                        platnostDo
                                        platnostOd
                                    }
                                    rozpracovana {
                                        cislo
                                        hash
                                    }
                                }
                                kapacitaMesicni
                                kapacitaNaDobuNeurcitou
                                predpokladanaRocniSpotreba {
                                    datumDo
                                    datumOd
                                    hodnota
                                }
                                stavNouzeDenniMinimum
                                stavNouzeDobaSnizeniOdberu
                                smluvniSpotreba
                                prepoctenaSpotreba
                                mnozstviMesicni
                                skupinaStavuNouze
                                vyuziti
                                tdd
                                charakterOdberu
                                adresa {
                                    ulice
                                    cisloPopisne
                                    cisloOrientacni
                                    mesto
                                    psc
                                }
                                pristroje {
                                    id
                                    idPristroje
                                    cislo
                                    material
                                    datumOd
                                    datumDo
                                }
                                typ
                            }
                        }
                    }
                }
            }
            ${AdresaDetailValueFragment.adresa}
            ${ObchodniPartnerNazevDetailValueFragment.obchodniPartner}
            ${ObchodniPartnerIcDetailValueFragment.obchodniPartner}
        `,

        typyDiagramuPlynQuery: gql`
            query OdbernaMistaTypDiagramuPlynTypyDiagramuHistorieSpotreb($eicEan: String!) {
                nastaveni {
                    odbernaMista {
                        typyDiagramuHistorieSpotrebPlyn {
                            id
                            klic
                            nazev
                        }
                    }
                }
                plyn {
                    odbernaMista {
                        smlouvyOdbernychMist(filter: {fulltextEicEan: $eicEan}) {
                            edges {
                                node {
                                    id
                                    stav
                                    datumOd
                                    datumDo
                                    zakaznik {
                                        id
                                        typ
                                        jmeno
                                        prijmeni
                                        nazevSpolecnosti
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        typyDiagramuBySkupinaElektrina: gql`
            query OdbernaMistaTypDiagramuElektrina($skupina: UcetSkupinaTyp, $omId: ID!) {
                nastaveni {
                    odbernaMista {
                        typyDiagramuHistorieSpotrebElektrina(skupina: $skupina, omId: $omId) {
                            id
                            klic
                            nazev
                            profilyDodavky(omId: $omId) {
                                klic
                                poradi
                                nazev
                                popis
                                typ
                                zdrojDat
                                mernaJednotka {
                                    nazev
                                }
                                tooltip
                                vychoziProNN
                                zobrazitPro
                            }
                            profilySpotreby(omId: $omId) {
                                klic
                                poradi
                                nazev
                                popis
                                typ
                                zdrojDat
                                mernaJednotka {
                                    nazev
                                }
                                tooltip
                                vychoziProNN
                                zobrazitPro
                            }
                            zobrazitPro
                        }
                    }
                }
            }
        `,

        historieSpotrebElektrina: gql`
            query OdbernaMistaHistorieSpotrebElektrina($id: ID!, $idPristroje: String!, $typDiagramu: ID!, $datumOd: ApiDate!, $datumDo: ApiDate!) {
                elektrina {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            elektrina {
                                historieSpotreb(idPristroje: $idPristroje, typDiagramu: $typDiagramu, datumOd: $datumOd, datumDo: $datumDo) {
                                    mereni {
                                        roleProfilu
                                        hodnoty {
                                            datumCasDo
                                            hodnota
                                            datumCasOd
                                            status
                                        }
                                    }
                                    informacniZprava
                                }
                            }
                        }
                    }
                }
            }
        `,

        smartMereniElektrina: gql`
            query OdbernaMistaSmartMereniElektrina($id: ID!, $idPristroje: String!, $typDiagramu: ID!, $datumOd: ApiDate!, $datumDo: ApiDate!) {
                elektrina {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            elektrina {
                                typTarifu {
                                    id
                                    klic
                                }
                                smartMereni(idPristroje: $idPristroje, typDiagramu: $typDiagramu, datumOd: $datumOd, datumDo: $datumDo) {
                                    datumCas
                                    spotrebaVt
                                    spotrebaNt
                                    statusOdectu
                                }
                            }
                        }
                    }
                }
            }
        `,

        smartMereniTypyDiagramu: gql`
            query OdbernaMistaSmartMereniTypyDiagramu($eicEan: String!) {
                nastaveni {
                    odbernaMista {
                        typyDiagramuSmartMereniElektrina {
                            id
                            klic
                            nazev
                        }
                    }
                }
                elektrina {
                    odbernaMista {
                        smlouvyOdbernychMist(filter: {fulltextEicEan: $eicEan}) {
                            edges {
                                node {
                                    id
                                    stav
                                    datumOd
                                    datumDo
                                    zakaznik {
                                        id
                                        typ
                                        jmeno
                                        prijmeni
                                        nazevSpolecnosti
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        smartMereniMaterialPristroju: gql`
            query OdbernaMistaSmartMereniMaterialyPristroju {
                nastaveni {
                    odbernaMista {
                        materialyPristrojuSmartMereniElektrina {
                            id
                            klic
                            nazev
                            aktivni
                            zobrazitSkupine
                        }
                    }
                }
            }
        `,

        typyDiagramuBySkupinaPlyn: gql`
            query OdbernaMistaTypDiagramuPlyn {
                nastaveni {
                    odbernaMista {
                        typyDiagramuHistorieSpotrebPlyn {
                            id
                            klic
                            nazev
                            profilySpotreby {
                                klic
                                nazev
                                popis
                                mernaJednotka {
                                    id
                                    nazev
                                }
                                tooltip
                            }
                        }
                    }
                }
            }
        `,

        historieSpotrebPlyn: gql`
            query OdbernaMistaHistorieSpotreb($id: ID!, $idPristroje: String!, $typDiagramu: ID!, $datumOd: ApiDate!, $datumDo: ApiDate!) {
                plyn {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            plyn {
                                historieSpotreb(idPristroje: $idPristroje, typDiagramu: $typDiagramu, datumOd: $datumOd, datumDo: $datumDo) {
                                    mereni {
                                        roleProfilu
                                        hodnoty {
                                            datumCas
                                            hodnota
                                            status
                                        }
                                    }
                                    informacniZprava
                                }
                            }
                        }
                    }
                }
            }
        `,

        historieOdectu: gql`
            query OdbernaMistaHistorieOdectuOdberneMisto($id: ID!, $idPristroje: String!) {
                elektrina {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            elektrina {
                                historieOdectu(idPristroje: $idPristroje) {
                                    grafCelkovaSpotrebaMeziOdecty {
                                        celkovaSpotrebaNt
                                        celkovaSpotrebaVt
                                        endDate
                                        startDate
                                    }
                                    grafPrumernaMesicniSpotrebaMeziOdecty {
                                        endDate
                                        startDate
                                        prumernaMesicniSpotrebaNt
                                        prumernaMesicniSpotrebaVt
                                    }
                                    odecty {
                                        stavNt
                                        stavVt
                                        duvodOdectu
                                        datum
                                        spotrebaNt
                                        spotrebaVt
                                    }
                                }
                                typTarifu {
                                    id
                                    klic
                                    nazev
                                }
                                pristroje {
                                    id
                                    idPristroje
                                    cislo
                                    material
                                    hdo
                                    datumOd
                                    datumDo
                                    typTarifu {
                                        id
                                        klic
                                        nazev
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        typyDiagramuBySkupinaQuery: gql`
            query OdbernaMistaTypDiagramu($skupina: UcetSkupinaTyp, $eicEan: String!, $omId: ID!) {
                nastaveni {
                    odbernaMista {
                        typyDiagramuHistorieSpotrebElektrina(skupina: $skupina, omId: $omId) {
                            id
                            klic
                            nazev
                            zobrazitPro
                        }
                    }
                }
                elektrina {
                    odbernaMista {
                        smlouvyOdbernychMist(filter: {fulltextEicEan: $eicEan}) {
                            edges {
                                node {
                                    id
                                    stav
                                    datumOd
                                    datumDo
                                    zakaznik {
                                        id
                                        typ
                                        jmeno
                                        prijmeni
                                        nazevSpolecnosti
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        historieOdectuPlyn: gql`
            query OdberneMistoPlynHistorieOdectu($id: ID!, $idPristroje: String!) {
                plyn {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            plyn {
                                historieOdectu(idPristroje: $idPristroje) {
                                    odecty {
                                        datum
                                        stav
                                        koeficient
                                        duvodOdectu
                                        spotreba
                                    }
                                    grafCelkovaSpotrebaMeziOdecty {
                                        celkovaSpotreba
                                        endDate
                                        startDate
                                    }
                                    grafPrumernaMesicniSpotrebaMeziOdecty {
                                        endDate
                                        startDate
                                        prumernaMesicniSpotreba
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        odberneMistoKapacita: gql`
            query OdberneMistoElektrinaKapacita($id: ID!, $typ: OdberneMistoElektrinaKapacitaTyp!) {
                elektrina {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            elektrina {
                                kapacita(typ: $typ) {
                                    datumOd
                                    datumDo
                                    hodnota
                                }
                            }
                        }
                    }
                }
            }
        `,

        odberneMistoKapacitaPlyn: gql`
            query OdberneMistoPlynKapacita($id: ID!, $klouzava: Boolean!, $mesicni: Boolean!, $neurcita: Boolean!) {
                plyn {
                    odbernaMista {
                        smlouvaOdbernehoMista(id: $id) {
                            id
                            plyn {
                                kapacitaKlouzavaZp @include(if: $klouzava) {
                                    datumDo
                                    datumOd
                                    hodnota
                                }
                                kapacitaMesicniZp @include(if: $mesicni) {
                                    datumDo
                                    datumOd
                                    hodnota
                                }
                                kapacitaNeurcitouZp @include(if: $neurcita) {
                                    datumDo
                                    datumOd
                                    hodnota
                                }
                            }
                        }
                    }
                }
            }
        `,

        smlouvyOdbernychMistElektrina: gql`
            query OdberneMistaSmlouvyElektrina($eicEan: String) {
                elektrina {
                    odbernaMista {
                        smlouvyOdbernychMist(filter: {fulltextEicEan: $eicEan}) {
                            edges {
                                node {
                                    id
                                    stav
                                    datumOd
                                    datumDo
                                    zakaznik {
                                        id
                                        typ
                                        jmeno
                                        prijmeni
                                        nazevSpolecnosti
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        smlouvyOdbernychMistPlyn: gql`
            query OdberneMistaSmlouvyPlyn($eicEan: String) {
                plyn {
                    odbernaMista {
                        smlouvyOdbernychMist(filter: {fulltextEicEan: $eicEan}) {
                            edges {
                                node {
                                    id
                                    stav
                                    datumOd
                                    datumDo
                                    zakaznik {
                                        id
                                        typ
                                        jmeno
                                        prijmeni
                                        nazevSpolecnosti
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        getHDOs: gql`
            query OdberneMistaHDOmobil {
                elektrina {
                    hdos {
                        getHdos {
                            adresa {
                                ulice
                                cisloPopisne
                                cisloOrientacni
                                mesto
                                psc
                                mistniCast
                            }
                            ean
                            hdo {
                                endDate
                                sazby {
                                    dny {
                                        casy {
                                            od
                                            do
                                        }
                                        denVTydnu
                                    }
                                    sazba
                                }
                            }
                            omId
                            vlastniNazevOM
                        }
                    }
                }
            }
        `,
        searchHdoKody: gql`
            query SearchHdoKody($idSmlouvy: ID!, $input: String!, $limit: Int) {
                elektrina {
                    odbernaMista {
                        searchHdoKody(idSmlouvy: $idSmlouvy, input: $input, limit: $limit) {
                            id
                            kody
                            povely
                        }
                    }
                }
            }
        `,
    },
};
