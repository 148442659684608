import {Adresa, ZadostElektrinaOdplombovaniOdberneMisto, ZmenaJisticeFormDataAdresaOm} from '@eon.cz/apollo13-graphql';
import {nullAsEmpty} from './StringOperations';

export const AddressFormatUtils = {
    /**
     * Format street line from the address
     *
     * @param {Adresa} adresa Adress
     */
    formatStreetLine: (adresa: Adresa | null | undefined) => {
        let res = adresa?.ulice ?? '';

        if (adresa?.cisloOrientacni && adresa?.cisloPopisne) {
            res += ` ${adresa?.cisloPopisne}/${adresa?.cisloOrientacni}`;
        } else if (adresa?.cisloPopisne) {
            res += ` ${adresa?.cisloPopisne}`;
        } else if (adresa?.cisloOrientacni) {
            res += ` ${adresa?.cisloOrientacni}`;
        }

        return res;
    },

    formatAddressLine: (adresa: Adresa | ZmenaJisticeFormDataAdresaOm | null | undefined) => (
        <>
            {AddressFormatUtils.formatStreetLine(adresa)} {(adresa && 'mesto' in adresa && adresa.mesto) ?? (adresa && 'obec' in adresa && adresa.obec)}{' '}
            {adresa?.mistniCast} {adresa?.psc}
        </>
    ),
};

/**
 * naformátování adresy do výsledného stringu dle analýzy
 */
export const formatAddress = (adresa: Adresa | ZmenaJisticeFormDataAdresaOm | ZadostElektrinaOdplombovaniOdberneMisto | undefined | null) => {
    if (adresa) {
        const {ulice, cisloPopisne, cisloOrientacni, mistniCast, psc} = adresa;
        const mesto = 'mesto' in adresa ? adresa.mesto : '';
        const obec = 'obec' in adresa ? adresa.obec : '';
        const hasCP = cisloPopisne && cisloPopisne.length > 0;
        const hasCO = cisloOrientacni && cisloOrientacni.length > 0;
        const hasCE = 'cisloEvidencni' in adresa ? adresa.cisloEvidencni && adresa.cisloEvidencni?.length > 0 : false;

        let res = '';

        if (nullAsEmpty(mesto)) {
            res += `${mesto}`;
        }

        if (nullAsEmpty(obec)) {
            res += `${obec}`;
        }

        if (nullAsEmpty(mistniCast)) {
            res += `, ${mistniCast}`;
        }

        if (nullAsEmpty(ulice)) {
            res += `, ${ulice}`;
        }

        if (hasCP && hasCO) {
            res += `, č.p.${cisloPopisne}/č.o.${cisloOrientacni}`;
        } else if (hasCO) {
            res += `, č.o.${cisloOrientacni}`;
        } else if (hasCP) {
            res += `, č.p.${cisloPopisne}`;
        } else if (hasCE) {
            res += `, č.e.${'cisloEvidencni' in adresa && adresa.cisloEvidencni}`;
        }

        res += `${res.length > 1 && !!psc ? ', ' : ''} ${nullAsEmpty(psc)}`;

        // When we ended with just two spaces, address is empty
        return res === '  ' ? '-' : res;
    }
};
