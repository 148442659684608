import {ApolloClient} from '@apollo/client';
import {NotificationsActionCreator} from '@eon.cz/apollo13-frontend-common';
import {UcetTyp} from '@eon.cz/apollo13-graphql';
import {captureMessage} from '@sentry/browser';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import * as React from 'react';
import {ErrorInfo, MouseEvent, ReactNode, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Lang} from '../../../Lang';
import {Store} from '../../../Store';
import {useAuthAction} from '../../Auth/actions';
import {getLoggerUserForDebug} from '../../Auth/service/AuthService';
import {PageContext} from '../contexts/PageContext';
import {isNotNullOrUndefinedOrEmpty} from '../services/FileService';

const ErrorFallbackContainer = dynamic(() => import('./ErrorFallbackContainer').then((mod) => mod.ErrorFallbackContainer));
const PageContentContainer = dynamic(() => import('./PageContentContainer').then((mod) => mod.PageContentContainer));

type Props = {
    readonly children: ReactNode;
    readonly title: string;
    readonly bottomNavigation?: string;
    readonly client?: ApolloClient<unknown>; // Injected apollo client
};

/**
 * Hlavní obalující komponenta pro každou funkcionalitu, které dodá základní layout a funkčnost.
 * Zobrazí horní lištu (AppBar), hlavní menu aplikace, spodní navigační menu, panel profilu.
 * Stará se o zobrazení notifikací a chyb v aplikaci.
 */

export const PageContainer: React.FC<Props> = ({title, bottomNavigation, children}) => {
    const {me} = React.useContext(PageContext);
    const isLogouting = useSelector((state: Store) => state.auth.isLogouting);
    const notifications = useSelector((state: Store) => state.notifications.notifications);
    const {addNotification, closeNotification} = NotificationsActionCreator(useDispatch());
    const {logout} = useAuthAction();
    const [errors] = useState<Array<{error?: Error; info?: ErrorInfo}>>([]);
    const [isOpenUserMenu, setIsOpenUserMenu] = useState<boolean>(false);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);

    const handleOnOpenUserMenu = (e: any) => {
        setIsOpenUserMenu(true);
        setUserMenuAnchorEl(e.currentTarget);
    };

    const handleOnCloseUserMenu = () => setIsOpenUserMenu(false);

    const handleSendFeedback = (e: MouseEvent<any, any>) => {
        if (e.shiftKey) {
            const publicRuntimeConfig = getConfig().publicRuntimeConfig;
            if (isNotNullOrUndefinedOrEmpty(publicRuntimeConfig.SENTRY_DSN)) {
                // User had SHIFT pressed and Sentry.io is configured on this environment - send trace
                const messageId = captureMessage(`User trace from ${getLoggerUserForDebug()}`);
                addNotification({text: <FormattedMessage id={Lang.LOG_ODESLAN} values={{messageId}} />});
            } else {
                addNotification({text: <FormattedMessage id={Lang.LOG_NEDOSTUPNY} />});
            }
        }
        e.preventDefault();
        e.stopPropagation();
    };

    // Zakazat volani dalsich query,pokud se prihlasuje EONadmin z admin portale.Volani patri staremu ME a zaroven ucet neni typu ZADATEL
    if ((!me?.skupina && me?.typ !== UcetTyp.ZADATEL && me?.registrovany) || !me) {
        return null;
    }

    if (errors && errors.length > 0) {
        return (
            <ErrorFallbackContainer
                errors={errors}
                handleSendfeedback={handleSendFeedback}
                logout={logout}
                handleOnOpenUserMenu={handleOnOpenUserMenu}
                handleOnCloseUserMenu={handleOnCloseUserMenu}
                userMenuAnchorEl={userMenuAnchorEl}
                isOpenUserMenu={isOpenUserMenu}
            />
        );
    }

    return (
        <PageContentContainer
            notifications={notifications}
            closeNotification={closeNotification}
            title={title}
            logout={logout}
            bottomNavigation={bottomNavigation}
            isLogouting={isLogouting}
            handleSendFeedback={handleSendFeedback}
            handleOnOpenUserMenu={handleOnOpenUserMenu}
            handleOnCloseUserMenu={handleOnCloseUserMenu}
            userMenuAnchorEl={userMenuAnchorEl}
            isOpenUserMenu={isOpenUserMenu}
        >
            {children}
        </PageContentContainer>
    );
};
