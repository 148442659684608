import {gql} from '@apollo/client';

export const CommonFragments = {
    historieStavu: gql`
        fragment HistorieStavu on StavPrechod {
            id
            stav
            udalost {
                typ
                data
            }
            vytvoreno
            vytvoril {
                ... on Ucet {
                    id
                    jmeno
                    prijmeni
                }
                ... on System {
                    nazev
                    role
                }
            }
        }
    `,
    adresa: gql`
        fragment AdresaSopPrepis on ZadostSopPrepisZakaznikAdresa {
            obec
            ulice
            mistniCast
            cisloPopisne
            cisloOrientacni
            stat
            cisloEvidencni
            psc
            pOBox
        }
    `,

    adresaSopZmenaJistice: gql`
        fragment SopZmenaJisticeZakaznikAdresa on SopZmenaJisticeZakaznikAdresa {
            obec
            ulice
            mistniCast
            cisloPopisne
            cisloOrientacni
            stat
            cisloEvidencni
            psc
        }
    `,

    komentar: gql`
        fragment Komentar on Komentar {
            id
            popis
            vytvoreno
            vytvoril {
                ... on Ucet {
                    id
                    jmeno
                    prijmeni
                }
                ... on System {
                    nazev
                    role
                }
            }
            zdroj
        }
    `,
    paginationPageInfo: gql`
        fragment PaginationPageInfo on PageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
        }
    `,
    prilohaZakladni: gql`
        fragment PrilohaZakladni on Priloha {
            id
            nazev
            mime
            velikost
            typ
            vytvoreno
            vytvoril {
                ... on Ucet {
                    id
                    jmeno
                    prijmeni
                }
                ... on System {
                    nazev
                    role
                }
            }
            podepsano
            podepsal {
                id
                jmeno
                prijmeni
            }
        }
    `,
    validationError: gql`
        fragment ValidationError on ValidationError {
            code
            field
            index
            message
            name
            relatedIndex
            type
        }
    `,
};
