import {gql} from '@apollo/client';
import {DetailValue, TooltipIcon} from '@eon.cz/apollo13-frontend-common';
import {Adresa} from '@eon.cz/apollo13-graphql';
import {FC} from 'react';
import {formatAddress} from '../../Utils';

interface Props {
    readonly adresa: Adresa | null | undefined;
    readonly short?: boolean;
    readonly title?: string;
}

export const AdresaDetailValueFragment = {
    adresa: gql`
        fragment AdresaDetailValue on Adresa {
            ulice
            cisloPopisne
            cisloOrientacni
            mesto
            mistniCast
            psc
        }
    `,
};

const getValue = (adresa: Adresa | null | undefined, short: boolean | undefined) => {
    const title = formatAddress(adresa);

    if (short) {
        return (
            <TooltipIcon textRaw={title}>
                <span>{adresa?.mesto}</span>
            </TooltipIcon>
        );
    }
    return title;
};

/**
 * Komponenta zobrazující adresu v určitém formátu
 */
export const AdresaDetailValue: FC<Props> = ({adresa, short, title, ...props}) => (
    <DetailValue title={title || 'Adresa'} value={getValue(adresa, short)} {...props} />
);
