import {gql} from '@apollo/client';
import {TooltipIcon} from '@eon.cz/apollo13-frontend-common/lib/components/icons/TooltipIcon';
import {ObchodniPartner, ObchodniPartnerTyp} from '@eon.cz/apollo13-graphql';
import {TableCell} from '@mui/material';
import {TableCellProps} from '@mui/material/TableCell';
import {makeStyles} from '@mui/styles';
import classnames from 'classnames';
import * as React from 'react';

interface Props extends TableCellProps {
    readonly obchodniPartner: ObchodniPartner;
    readonly short?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        whiteSpace: 'nowrap',
    },
}));

const MAX_LENGTH = 30;

const getNazevWithTooltip = (nazevSpolecnosti: string) => (
    <TooltipIcon textRaw={nazevSpolecnosti}>
        <span>
            {nazevSpolecnosti.substr(0, MAX_LENGTH)}
            ...
        </span>
    </TooltipIcon>
);

export const ObchodniPartnerTableCellFragment = {
    zakaznik: gql`
        fragment ObchodniPartnerTableCell on ObchodniPartner {
            id
            nazevSpolecnosti
            jmeno
            prijmeni
            typ
        }
    `,
};

/**
 * Komponenta pro zobrazení jména a příjmení nebo názvu společnosti z objektu obchodního partnera.
 * Pokud je předána proměnná 'short', název společnosti se zkrátí a celý název se zobrazí v Tooltipu.
 */
export const ObchodniPartnerTableCell: React.FC<Props> = ({obchodniPartner: op, short, ...props}) => {
    const classes = useStyles({});
    const nazevSpolecnosti = op?.nazevSpolecnosti ?? '';
    return (
        <TableCell {...props} className={classnames({[classes.root]: short})}>
            {op.typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA
                ? short && nazevSpolecnosti?.length > MAX_LENGTH + 3
                    ? getNazevWithTooltip(op?.nazevSpolecnosti ?? '')
                    : op.nazevSpolecnosti
                : `${op.jmeno} ${op.prijmeni}`}
        </TableCell>
    );
};
