/**
 * Odebere počáteční nuly z řetězce a vrátí ho
 * @param {string} str
 */
export const removeLeadingZerosFromString = (str: string | undefined) => {
    return str?.replace(/^0+(?!$)/, '');
};

/**
 * Pokud je hodnota typu string, odstraní mezery před a za hodnotou, jinak vrátí původní hodnotu
 * @param {any} value
 */
export const trimSpaces = (value: any) => (typeof value === 'string' ? (value as string).trim() : value);

/**
 * Convert null and undefined to an empty string
 */
export const nullAsEmpty = (s: string | null | undefined): string => {
    return typeof s === 'string' ? s : '';
};

/**
 * The function `removeSecondFromTime` takes a time string in the format "HH:MM:SS" and returns a new
 * time string in the format "HH:MM" by removing the seconds.
 * @param {string} time - The `time` parameter is a string representing a time in the format
 * "HH:MM:SS".
 * @returns a string that represents the time with the seconds removed.
 */
export const removeSecondFromTime = (time: string | undefined) => {
    return time?.split(':').slice(0, 2).join(':');
};
