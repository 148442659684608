import {gql} from '@apollo/client';

export const OdbernaMistaMutations = {
    gql: {
        toggleNotifications: gql`
            mutation OvladaniNotifikaciElektrinaMutation($input: ToggleNotificationsOdbernehoMistaInput!) {
                elektrina {
                    odbernaMista {
                        toggleNotifications(input: $input)
                    }
                }
            }
        `,
        updateOdberneMistoNazevElektrina: gql`
            mutation UpdateOdberneMistoNazevElektrinaMutation($input: UpdateSmlouvaOdbernehoMistaInput!) {
                elektrina {
                    odbernaMista {
                        update(input: $input) {
                            id
                        }
                    }
                }
            }
        `,

        updateOdberneMistoNazevPlyn: gql`
            mutation UpdateOdberneMistoNazevPlynMutation($input: UpdateSmlouvaOdbernehoMistaInput!) {
                plyn {
                    odbernaMista {
                        update(input: $input) {
                            id
                        }
                    }
                }
            }
        `,

        sendReportOdbernychMistElektrina: gql`
            mutation SendReportOdbernychMistElektrinaMutation($input: SendReportSmlouvaOdbernehoMistaInput!) {
                elektrina {
                    odbernaMista {
                        sendReport(input: $input)
                    }
                }
            }
        `,
        sendReportOdbernychMistPlyn: gql`
            mutation SendReportOdbernychMistPlynMutation($input: SendReportSmlouvaOdbernehoMistaInput!) {
                plyn {
                    odbernaMista {
                        sendReport(input: $input)
                    }
                }
            }
        `,
        savePovelyHdo: gql`
            mutation SavePovelyHdoMutation($idSmlouvy: ID!, $povely: String!) {
                elektrina {
                    odbernaMista {
                        savePovelyHdo(idSmlouvy: $idSmlouvy, povely: $povely)
                    }
                }
            }
        `,
        deletePovelyHdo: gql`
            mutation DeletePovelyHdoMutation($idSmlouvy: ID!) {
                elektrina {
                    odbernaMista {
                        deletePovelyHdo(idSmlouvy: $idSmlouvy)
                    }
                }
            }
        `,
    },
};
