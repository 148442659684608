import {gql} from '@apollo/client';
import {TooltipIcon} from '@eon.cz/apollo13-frontend-common/lib/components/icons/TooltipIcon';
import {Adresa} from '@eon.cz/apollo13-graphql';
import {TableCell} from '@mui/material';
import {TableCellProps} from '@mui/material/TableCell';
import {makeStyles} from '@mui/styles';
import {FC} from 'react';
import {formatAddress} from '../../Utils';

interface Props extends TableCellProps {
    readonly adresa: Adresa | null | undefined;
    readonly short?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        whiteSpace: 'nowrap',
    },
}));

export const AdresaFragment = {
    adresa: gql`
        fragment AdresaFragment on Adresa {
            ulice
            cisloPopisne
            cisloOrientacni
            mesto
            psc
            mistniCast
        }
    `,
};

/**
 * Komponenta zobrazující adresu v určitém formátu
 */
export const AdresaTableCell: FC<Props> = ({adresa, short, ...props}) => {
    const classes = useStyles({});
    const reformatAddress = formatAddress(adresa);
    return (
        <TableCell {...props} className={classes.root}>
            {short ? (
                <TooltipIcon textRaw={reformatAddress}>
                    <span>{adresa?.mesto}</span>
                </TooltipIcon>
            ) : (
                reformatAddress
            )}
        </TableCell>
    );
};
