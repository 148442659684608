import {gql} from '@apollo/client';
import {DetailValue} from '@eon.cz/apollo13-frontend-common';
import {ObchodniPartner, ObchodniPartnerTyp} from '@eon.cz/apollo13-graphql';
import * as React from 'react';
import {Lang} from '../../../Lang';

interface Props {
    readonly obchodniPartner: ObchodniPartner;
}

export const ObchodniPartnerNazevDetailValueFragment = {
    obchodniPartner: gql`
        fragment ObchodniPartnerNazevDetailValue on ObchodniPartner {
            typ
            nazevSpolecnosti
            jmeno
            prijmeni
        }
    `,
};

const getLabel = (typ: ObchodniPartnerTyp) =>
    typ === 'PRAVNICKA_OSOBA' ? Lang.ODBERNA_MISTA_ZAKAZNIK_NAZEV_FIRMY : Lang.ODBERNA_MISTA_ZAKAZNIK_JMENO_PRIJMENI;

const getValue = ({typ, nazevSpolecnosti, jmeno, prijmeni}: ObchodniPartner) =>
    typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA ? nazevSpolecnosti : `${jmeno} ${prijmeni}`;

/**
 * Komponenta pro zobrazení jména a příjmení nebo názvu společnosti z objektu obchodního partnera
 */
export const ObchodniPartnerNazevDetailValue: React.FC<Props> = ({obchodniPartner, ...props}) => (
    <DetailValue title={getLabel(obchodniPartner.typ)} value={<strong>{getValue(obchodniPartner)}</strong>} {...props} />
);
